import { firestoreAction } from 'vuexfire'
import { quizCollection } from "../plugins/firebase";
import { ANSWER_TYPES, QUESTION_TYPES } from "../constants/questions"

export const playStore = {
    state: {
        players: [],
        votes: [],
    },
    mutations: {
        setQuizzes: (state, quizzes) => state.quizzes = quizzes
    },
    actions: {
        loadPlayers: firestoreAction(({ bindFirestoreRef }, quiz) => {
            return bindFirestoreRef('players', quizCollection.doc(quiz.id).collection("players").orderBy("created", "desc"))
        }),
        loadVotes: firestoreAction(({ bindFirestoreRef }, quiz) => {
            return bindFirestoreRef('votes', quizCollection.doc(quiz.id).collection("votes"))
        }),
    }
}

export function filterVoteForOption(question, vote, option) {
    if (question.answerType === ANSWER_TYPES.MULTIPLE_CHOICE)
        return vote.options.includes(option);
    else
        return vote.option === option;
}

export function evaluateOption(question, optionId) {

    /* these are cases in which the correct answers have not been changed after switching to multiple choice */
    if (question.answerType === ANSWER_TYPES.MULTIPLE_CHOICE && !question.correct.every)
        question.correct = [question.correct];

    if (question.answerType === ANSWER_TYPES.MULTIPLE_CHOICE) {
        return question.correct && question.correct.includes(optionId);
    } else
        return question.correct === optionId;
}

// evaluates the correctness of a vote
// returns 0 if the vote is incorrect, 1 if the vote is correct
// in case of a non-knowledge question, the vote is always correct
export function evaluateVote(question, vote) {
    if (question.type !== QUESTION_TYPES.KNOWLEDGE)
        return true;

    /* these are cases in which the correct answers have not been changed after switching to multiple choice */
    if (question.answerType === ANSWER_TYPES.MULTIPLE_CHOICE && !question.correct.every)
        question.correct = [question.correct];

    if (question.answerType === ANSWER_TYPES.MULTIPLE_CHOICE) {

        /* first check that no wrong answer has been selected */
        const wrongAnswerSelected = vote.options.some(option => !question.correct.map(o => question.options[o]).includes(option));

        if (wrongAnswerSelected)
            return 0;

        /* calculate the percentage of correct answers */
        const correctAnswers = vote.options.filter(option => question.correct.map(o => question.options[o]).includes(option));
        const percentageCorrect = correctAnswers.length / question.correct.length;

        return percentageCorrect

    }

    /* if the question is not multiple choice, the vote is correct if the option is correct */
    return question.correct === question.options.indexOf(vote.option) ? 1 : 0;
}

export function leaderboard() {
    const players = this.$store.state.play.players.map(p => ({ userid: p.userid, name: p.id, points: 0, time: 0 }));
    const votes = this.$store.state.play.votes.map(v => ({
        ...v,
        timestamp: v.timestamp.seconds * 1000 + v.timestamp.nanoseconds / 1000
    }));

    votes.forEach(vote => {
        const question = this.quiz.questions[vote.question];
        const correct = evaluateVote(question, vote);

        // find all votes for the current question, sort them by timestamp and map them to the index
        const sortedQuestionTimestamps = votes
            .filter(v => v.question === vote.question)
            .filter(v => evaluateVote(question, v) > 0)
            .sort((a, b) => a.timestamp - b.timestamp);

        if (correct > 0) {
            const player = players.find(p => p.userid === vote.userid);

            // give point to the player
            player.points += correct;

            // in case of an undefined time bonus, the player gets the time bonus as well
            if (typeof question.timeBonus == 'undefined' ? true : question.timeBonus)
                player.time += sortedQuestionTimestamps.length - sortedQuestionTimestamps.indexOf(vote);
        }
    });

    // sort by points and then by time
    return players.sort((a, b) => b.time - a.time).sort((a, b) => b.points - a.points);
}